<template>
    <div>
        <div class="flex items-center mb-4 mt-3 justify-between">
            <div class="flex items-center">
                <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
                <p class="text-xl ml-2 font-bold" style="color: #ff8e3b">
                    {{ project }} - Results & Achievements
                </p>
            </div>
        </div>
        <!-- <p class="font-bold mt-3 text-lg" style="margin-left: 20px">{{project}} - Results & Achievements</p> -->
        <div style="padding: 20px;padding-top: 0px;">
            <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
                :highlight-current-row="false" :highlight-selection-row="false"
                :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle"
                :row-class-name="'custom-row-bg'">
                <el-table-column prop="Student" label="Student" width="160px">
                    <template slot-scope="scope">
                        {{ scope.row.student.student_givenName_pinyin + ' ' +
                            scope.row.student.student_lastName_pinyin }}
                    </template>
                </el-table-column>
                <el-table-column prop="student.student_email" label="Email" width="180px"></el-table-column>
                <el-table-column prop="student.student_graduation" label="Grad Year" width="100px"></el-table-column>
                <el-table-column prop="" label="Score">
                    <template slot-scope="scope">
                        <div v-for="(score, index) in scope.row.scores" :key="index">
                            <p>{{ score.application_event + ' : ' + score.total_score }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="Achievement">
                    <template slot-scope="scope">
                        <div v-for="(award, index) in scope.row.awards" :key="index">
                            <p>{{ award.application_event + ' : ' + award.award_lable }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="Operation" width="120px">
                    <template slot-scope="scope">
                        <el-button type="info" size="small">Certificate</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import {
    getStudentAppsByAccount
} from '../../api/eae'
export default ({
    name: 'index',
    data() {
        return {
            project: this.$route.query.project,
            project_id: this.$route.query.project_id,
            account_id: this.$route.query.account_id,
            dataList: []
        }
    },
    mounted() {
        this.fetechData()
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        fetechData() {
            getStudentAppsByAccount(this.account_id, this.project_id).then((res) => {
                this.dataList = res.data
            })
        },
        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
        },
    }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
    font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: Ubuntu-Bold;
}
</style>